// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // appVersion: 'v726demo1',
  // USERDATA_KEY: 'authf649fc9a5f55',
  OTP_DATA_KEY: 'otpf649fc9a5f55',
  isMockEnabled: false,
  WEB_API_ENC_KEY: '3596cff066cd9e2a237ab892c96b05da',
  WEB_API_ENC_IV: '88829bf8da6a1c7c616790029c048c42',
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  BASE_URL: 'https://dashboard.detect.solutions/', // 'https://dashboard.detect.solutions/',
  API_BASE_URL: 'https://dashboard.detect.solutions/api/', // 'https://dashboard.detect.solutions/api',
  USER_BASE_URL: 'https://dashboard.detect.solutions/api/users/', // 'https://dashboard.detect.solutions/api/users/',
  USER: {
      USER_AUTH: 'login',
      LOGOUT: 'logout',
      NEW_ORG_USER: 'new-org-user',
      LIST_ORG_USER: 'list-org-users',
      DELETE_ORG_USER: 'delete-org-users',
      UPDATE_ORG_USER: 'update-org-user',
      GET_ALL_USERS: 'list',
      GET_CURRENT_USER_DETAILS: 'me',
      GET_USER_DETAILS: 'view',
      UPDATE_PROFILE: 'update-profile',
      UPDATE_PASSWORD: 'update-password',
      ADD_USER: 'add',
      OTP: 'otp',
      DELETE_USER: 'delete',
      SEARCH_USER: 'search',
      FORGOT_PASSWORD: 'forgot-password',
      RESET_PASSWORD: 'reset-password',
  },
  MACHINE_BASE_URL: 'https://dashboard.detect.solutions/api/machine/', // 'https://dashboard.detect.solutions/api/machine/',
  MACHINE:{
    MACHINE_LIST:'get-machines-list',
    REMEDIATE_TEST: 'remediate-test',
    REVERT_REMEDIATE_TEST: 'revert/remediate-test',
    MACHINE_SENSOR_RUN:'execute-machine-sensor',
    GET_IP_REP_FIREWALL_STATUS: 'get-ip-rep-firewall-status',
    IP_BLOCK_UNBLOCK: 'ip-block-unblock',
    GET_WAZUH_VULNERABILITIES: 'get-wazuh-vulnerabilities',
    GET_DOMAINS: 'get-domains',
    GET_ORG_FP_DOMAINS: 'get-org-fp-domains',
    GET_ORG_FP_IP: 'get-org-fp-ip',
    GET_FP_DOMAINS: 'get-fp-domains',
    GET_FP_IP: 'get-fp-ip',
    DOMAIN: 'domain',
    IP: 'ip',
    MARK_FALSE_POSITIVE: 'mark-false-positive',
    MARK_VALID_FALSE_POSITIVE: 'mark-valid-false-positive',
    LIST_COMMANDS:'commands',
    PATCH_COMMAND:'command',
  },

  ORG_BASE_URL: 'https://dashboard.detect.solutions/api/orgs/', // 'https://dashboard.detect.solutions/api/orgs/',
  ORG: {
    NEW_ORG:'new-organization',
    DELETE_ORG:'delete-organization',
    LIST_ORG:'list',
    LIST_NAME:'name-list',
    CLIENTS_STATUS:'clients-status',
    UPDATE_DATABASE:'update-database',
    UPDATE_DATABASE_LOGS: 'update-database-logs',
    CLEAR_DATABASE: 'clear-database',
    UPDATE_ALLOWED_TEST_COUNT: 'update-allowed-test-count',
    UPDATE_ORG_EMAIL: 'update-org-email',
    ANALYTICS: 'analytics',
    ALERT_EMAILS: 'get-alert-email',
    UPDATE_ALERT_EMAIL: 'update-alert-email',
    DELETE_ALERT_EMAIL: 'delete-alert-email',
    GET_ORG_SENSOR_VERSION: 'get_org_sensor_version',
    SET_ORG_SENSOR_VERSION: 'set_org_sensor_version'
  },
  CLIENT_BASE_URL: 'https://dashboard.detect.solutions/api/clients/', // 'https://dashboard.detect.solutions/api/clients/',
  CLIENT: {
    LIST_CLIENTS:'list',
    LIST_COMMANDS:'commands',
    PATCH_COMMAND:'command',
    GET_CLIENT_BY_KEY: 'report',
    GET_CLIENT_CVE_BY_KEY: 'get-cves',
    GET_SCA: 'get-sca',
    GET_IP_REP: 'get-ip-rep',
    GET_IP_REP_MACHINE: 'get-ip-rep-machine',
    GET_IP_REP_MACHINE_SVC: 'get-ip-rep-machine-svc',
    GET_MISSING_WIN_UPDATES: 'get-missing-win-updates',
    GET_TESTCASES: 'testcases',
  },
  TEST_BASE_URL: 'https://dashboard.detect.solutions/api/tests/', // 'https://dashboard.detect.solutions/api/tests/',
  TEST: {
    LIST_TESTS:'list',
    LIST_KEYS: 'keys-list',
    LIST_TEST_LOGS:'logs',
    UPDATE_TESTCASE_CLIENTKEYS:'update-testcase-clientkeys',
    UPDATE_TESTCASE_ORGWIDE:'update-testcase-orgwide'
  },
  RECAPTCHA: {
    SITE_KEY: "6Ld36uwlAAAAAG0L6sAOCt6Jc66f6iJCmHDvg5mM",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
