import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  constructor() {}

  getCurrentDomain(): string {
    return window.location.host;
  }
}