// http://127..0.0.1:3000
// http://localhost::3000

// https://dashboard..detect.solutions

// $env:NODE_OPTIONS='--openssl-legacy-provider'   
import { DomainService } from './domain.service';
// const BASE_DOMAIN = `http://${new DomainService().getCurrentDomain()}`;
const BASE_DOMAIN = `https://dev.detect.solutions`;
// const BASE_DOMAIN = `http://localhost:3000`;
export const SOCKET_BASE_DOMAIN = `ws://${new DomainService().getCurrentDomain()}:3040`;





export const environment = {
  production: false,
  OTP_DATA_KEY: 'otpf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: BASE_DOMAIN,
  WEB_API_ENC_KEY: '3596cff066cd9e2a237ab892c96b05da',
  WEB_API_ENC_IV: '88829bf8da6a1c7c616790029c048c42',
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  BASE_URL: BASE_DOMAIN + '/', // BASE_DOMAIN + '/',
  API_BASE_URL: BASE_DOMAIN + '/api/', // BASE_DOMAIN + '/api',
  USER_BASE_URL: BASE_DOMAIN + '/api/users/', // BASE_DOMAIN + '/api/users/',
  USER: {
      USER_AUTH: 'login',
      REFRESH_AUTH: 'refresh-token',
      LOGOUT: 'logout',
      NEW_ORG_USER: 'new-org-user',
      LIST_ORG_USER: 'list-org-users',
      DELETE_ORG_USER: 'delete-org-users',
      UPDATE_ORG_USER: 'update-org-user',
      GET_ALL_USERS: 'list',
      GET_CURRENT_USER_DETAILS: 'me',
      GET_USER_DETAILS: 'view',
      UPDATE_PROFILE: 'update-profile',
      UPDATE_PASSWORD: 'update-password',
      ADD_USER: 'add',
      OTP: 'otp',
      DELETE_USER: 'delete',
      SEARCH_USER: 'search',
      FORGOT_PASSWORD: 'forgot-password',
      RESET_PASSWORD: 'reset-password',
  },
  MACHINE_BASE_URL: BASE_DOMAIN + '/api/machine/', // BASE_DOMAIN + '/api/machine/',
  MACHINE:{
    MARK: 'mark',
    TEST: 'test',
    REMEDIATED: 'remediated',
    MACHINE_LIST:'get-machines-list',
    MACHINE_MIN_INFO_LIST:'get-machine-mininfo-list',
    REMEDIATE_TEST: 'remediate-test',
    REVERT_REMEDIATE_TEST: 'revert/remediate-test',
    MACHINE_SENSOR_RUN:'execute-machine-sensor',
    MACHINE_DELETE_CMD:'delete-cmd',
    GET_IP_REP_FIREWALL_STATUS: 'get-ip-rep-firewall-status',
    IP_BLOCK_UNBLOCK: 'ip-block-unblock',
    GET_WAZUH_VULNERABILITIES: 'get-wazuh-vulnerabilities',
    GET_APP_THREATS: 'get-app-threats',
    GET_DOMAINS: 'get-domains',
    GET_EMAIL_DOMAINS: 'get-email-domains',
    GET_ORG_FP_DOMAINS: 'get-org-fp-domains',
    GET_ORG_FP_IP: 'get-org-fp-ip',
    GET_FP_DOMAINS: 'get-fp-domains',
    GET_FP_IP: 'get-fp-ip',
    DOMAIN: 'domain',
    IP: 'ip',
    MARK_FALSE_POSITIVE: 'mark-false-positive',
    MARK_VALID_FALSE_POSITIVE: 'mark-valid-false-positive',
    LIST_COMMANDS:'commands',
    PATCH_COMMAND:'command',
    ALERTS: 'alerts',
    STATUS: 'status',
    MISSING_WIN_UPDATES_PER_MACHINE: 'missing_win_updates_per_machine',
  },

  ORG_BASE_URL: BASE_DOMAIN + '/api/orgs/', // BASE_DOMAIN + '/api/orgs/',
  ORG: {
    NEW_ORG:'new-organization',
    DELETE_ORG:'delete-organization',
    SUGGEST_ORG_NAMES:'suggest-org-names', // see function: suggest_org_names in src\app\modules\Beacon\_services\customers.service.ts
    LIST_ORG:'list',
    GET_ORG_BY_ID:'get_org_by_id',
    INSTALLER: 'installer',
    SENSOR: 'sensor',
    LIST_NAME:'name-list',
    CLIENTS_STATUS:'clients-status',
    CLIENTS_REPORT_DELETE:'client-report-delete',
    UPDATE_DATABASE:'update-database',
    UPDATE_DATABASE_LOGS: 'update-database-logs',
    CLEAR_DATABASE: 'clear-database',
    UPDATE_ALLOWED_TEST_COUNT: 'update-allowed-test-count',
    UPDATE_ORG_EMAIL: 'update-org-email',
    UPDATE_ORG_DOMAIN: 'update-org-domain',
    UPDATE_SVC_SET: 'update-svc-set',
    ANALYTICS: 'analytics',
    ALERT_EMAILS: 'get-alert-email',
    UPDATE_EXPIRY_DATE: 'update-org-expiry',
    UPDATE_ALERT_EMAIL: 'update-alert-email',
    DELETE_ALERT_EMAIL: 'delete-alert-email',
    UPDATE_SLACK_WEBHOOK: 'update-slack-webhook',
    GET_ORG_SENSOR_VERSION: 'get_org_sensor_version',
    SET_ORG_SENSOR_VERSION: 'set_org_sensor_version',
    DOWNLOAD_MAC_SENSOR: 'download-mac-sensor',
    SHODAN: 'shodan',
    RECORD: 'record',
    ELASTIC_TOKEN: 'es_token',
    SET_UPGRADE_OPTION: 'set_upgrade_option',
    TOP_MISSING_WIN_UPDATES: 'top_missing_win_updates',
    GET_ORG_NOTIFICATION_DETAILS: 'get_org_notification_details'
  },
  CLIENT_BASE_URL: BASE_DOMAIN + '/api/clients/', // BASE_DOMAIN + '/api/clients/',
  CLIENT: {
    LIST_CLIENTS:'list',
    LIST_COMMANDS:'commands',
    PATCH_COMMAND:'command',
    GET_CLIENT_BY_KEY: 'report',
    GET_CLIENT_CVE_BY_KEY: 'get-cves',
    GET_SCA: 'get-sca',
    GET_IP_REP: 'get-ip-rep',
    GET_IP_REP_MACHINE: 'get-ip-rep-machine',
    GET_IP_REP_MACHINE_SVC: 'get-ip-rep-machine-svc',
    GET_MISSING_WIN_UPDATES: 'get-missing-win-updates',
    GET_TESTCASES: 'testcases',
  },
  TEST_BASE_URL: BASE_DOMAIN + '/api/tests/', // BASE_DOMAIN + '/api/tests/',
  TEST: {
    LIST_TESTS:'list',
    LIST_KEYS: 'keys-list',
    LIST_TEST_LOGS:'logs',
    UPDATE_TESTCASE_CLIENTKEYS:'update-testcase-clientkeys',
    UPDATE_TESTCASE_ORGWIDE:'update-testcase-orgwide'
  },
  DETECT_DEVICE_BASE_URL: '/detect-device/', // '/detect-device/',
  DETECT_DEVICE: {
    SCANS: 'scans',
    DEVICES: 'devices',
    DEVICE: 'device',
    SCAN_CONFIGURATION: 'scan-configuration',
    DELETE_DB: 'delete-database',
    TEST: 'test',
    PERFORM_REMEDIATION: 'perform-remediation',
    PERFORM_REVERT_REMEDIATION: 'perform-remediation',
    FALSE_POSITIVE: 'false-positive',
    IDS_CONFIGURATION: 'ids-configuration',
  },
  RECAPTCHA: {
    SITE_KEY: "6Ld36uwlAAAAAG0L6sAOCt6Jc66f6iJCmHDvg5mM",
  }
};



