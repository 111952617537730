import { Injectable, OnDestroy } from '@angular/core';
import {fromEvent, Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize, delay } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { EncryptionService } from 'src/app/Utils/encryption.service';
import { environment } from 'src/environments/environment';
import { environment as config } from '../../../../environments/environment.dev';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private otpLocalStorageToken = `${environment.appVersion}-${environment.OTP_DATA_KEY}`;
  // public fields
  currentUser$: Observable<UserModel>;
  isOtpVerified$: Observable<boolean>;
  isOtpSent$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isOtpVerifiedSubject$: BehaviorSubject<boolean>;
  isOtpSentSubject$: BehaviorSubject<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  tokenSubscription = new Subscription()



  private tokenRefreshSubscription: Subscription;
  private isPageVisible = true;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  get isOtpVerifiedValue(): boolean {
    return this.isOtpVerifiedSubject$.value;
  }

  set isOtpVerifiedValue(status: boolean) {
    this.isOtpVerifiedSubject$.next(status);
  }

  get isOtpSendValue(): boolean {
    return this.isOtpSentSubject$.value;
  }

  set isOtpSendValue(status: boolean) {
    this.isOtpSentSubject$.next(status);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private encService: EncryptionService,
    public http: HttpClient,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.isOtpSentSubject$ = new BehaviorSubject<boolean>(false);
    this.isOtpVerifiedSubject$ = new BehaviorSubject<boolean>(false);
    this.isOtpSent$ = this.isOtpSentSubject$.asObservable();
    this.isOtpVerified$ = this.isOtpVerifiedSubject$.asObservable();
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);

      // Add an event listener for page visibility change
      const visibilityChange$ = fromEvent(document, 'visibilitychange');
      this.unsubscribe.push(visibilityChange$.subscribe(this.handleVisibilityChange.bind(this)));

      const clickEvent$ = fromEvent(window, 'click');
      this.unsubscribe.push(clickEvent$.subscribe(this.onClickAnywhere.bind(this)));
  }

  onClickAnywhere(event: MouseEvent): void {
    // Handle the click event here

    if (!this.currentUserValue) {
      return;
    }

    const tokenExpiry = Number(localStorage.getItem("tokenExpiry"));

    if (!isNaN(tokenExpiry)) {
      const currentExpiryTime = new Date(tokenExpiry);
      const remainingTimeInSeconds = Math.floor((currentExpiryTime.getTime() - new Date().getTime()));
      const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds

      if (remainingTimeInSeconds < tenMinutesInMilliseconds) {
        // startTokenRefreshCounter when remainingTime is less than 10 minutes
        console.log("Less than 10 minutes remaining!");

        this.startTokenRefreshCounter(10);
      } else {
        // Do something else when remainingTime is greater than or equal to 10 minutes
        // console.log("10 minutes or more remaining.");
      }


    } else {
      console.error("Invalid token expiry in localStorage.");
    }

  }

  handleVisibilityChange() {
    this.isPageVisible = !document.hidden;
    if (this.isPageVisible && this.currentUserValue) {

      const tokenExpiry = Number(localStorage.getItem("tokenExpiry"));

      if (!isNaN(tokenExpiry)) {
        const currentExpiryTime = new Date(tokenExpiry);
        const remainingTimeInSeconds = Math.floor((currentExpiryTime.getTime() - new Date().getTime()));
        const tenMinutesInMilliseconds = 10 * 60 * 1000; // 10 minutes in milliseconds
  
        // check if it is going to expire then reload
        if (remainingTimeInSeconds < 10) {
          window.location.reload();
        }

        if (remainingTimeInSeconds < tenMinutesInMilliseconds) {
          // startTokenRefreshCounter when remainingTime is less than 10 minutes
          console.log("Less than 10 minutes remaining!");
  
          this.startTokenRefreshCounter(10);
        } else {
          // Do something else when remainingTime is greater than or equal to 10 minutes
          // console.log("10 minutes or more remaining.");
        }
  
  
      } else {
        console.error("Invalid token expiry in localStorage.");
      }
      
    } else {
      console.log("page is nooooooooooooot visible");

      // Page became hidden, unsubscribe the token refresh counter
      this.unsubscribeTokenRefreshCounter();
    }
  }

  unsubscribeTokenRefreshCounter() {
    if (this.tokenRefreshSubscription) {
      this.tokenRefreshSubscription.unsubscribe();
      this.tokenRefreshSubscription = null;
    }
  }

  private startTokenRefreshCounter(remainingTimeInSeconds: number) {
    
    
    if (this.isPageVisible) {

          
      console.log("doneeeeeeeeeeeeeeeee");
      this.authHttpService.refreshTokenFunc(this.getAuthFromLocalStorage().refreshToken).subscribe((newAuth) => {
        if (!newAuth) {
          // Token refresh failed or user not logged in
          this.logout();
          window.location.reload();
        } else {
          // const timeout = new Date((JSON.parse(atob(newAuth.split('.')[1]))).exp * 1000);
          // const remainingTime = Math.floor((timeout.getTime() - new Date().getTime()) / 1000);

          console.log(newAuth);

          const result = this.setAuthFromLocalStorage(newAuth);
    
          const timeout = new Date((JSON.parse(atob(newAuth.authToken.split('.')[1]))).exp * 1000);
          
          localStorage.setItem("tokenExpiry", timeout.getTime() + "");        
  
          const remainingTimeInSeconds = Math.floor((timeout.getTime() - new Date().getTime()));
          this.expirationCounter(remainingTimeInSeconds);

          // console.log(timeout);
          // console.log(remainingTime);

          // this.startTokenRefreshCounter(remainingTime);
        }
      });
    }
    
    // this.unsubscribeTokenRefreshCounter();

    // console.log("weeeeeeeeeeeeeeeee");
    // this.tokenRefreshSubscription = of(null)
    //   .pipe(delay(remainingTimeInSeconds))
    //   .subscribe(() => {
    //     if (this.isPageVisible) {

          
    //       console.log("doneeeeeeeeeeeeeeeee");
    //       this.authHttpService.refreshTokenFunc(this.getAuthFromLocalStorage().refreshToken).subscribe((newAuth) => {
    //         if (!newAuth) {
    //           // Token refresh failed or user not logged in
    //           this.logout();
    //           window.location.reload();
    //         } else {
    //           // const timeout = new Date((JSON.parse(atob(newAuth.split('.')[1]))).exp * 1000);
    //           // const remainingTime = Math.floor((timeout.getTime() - new Date().getTime()) / 1000);

    //           console.log(newAuth);

    //           const result = this.setAuthFromLocalStorage(newAuth);
        
    //           const timeout = new Date((JSON.parse(atob(newAuth.authToken.split('.')[1]))).exp * 1000);
              
    //           localStorage.setItem("tokenExpiry", timeout.getTime() + "");        
      
    //           const remainingTimeInSeconds = Math.floor((timeout.getTime() - new Date().getTime()));
    //           this.expirationCounter(remainingTimeInSeconds);

    //           // console.log(timeout);
    //           // console.log(remainingTime);

    //           // this.startTokenRefreshCounter(remainingTime);
    //         }
    //       });
    //     } else {
    //       this.unsubscribeTokenRefreshCounter();
    //     }
    //   });
  }













  public getTokenExpiryFromLocalStorage(): Number {
    try {
      return Number(localStorage.getItem("tokenExpiry"));
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        
        const timeout = new Date((JSON.parse(atob(auth.authToken.split('.')[1]))).exp * 1000);
        
        localStorage.setItem("tokenExpiry", timeout.getTime() + "");        



        // const remainingTimeInSeconds = Math.floor((timeout.getTime() - new Date().getTime()) / 1000);
        // this.startTokenRefreshCounter(remainingTimeInSeconds);

        // const timeout = new Date((JSON.parse(atob(auth.authToken.split('.')[1]))).exp * 1000);
        // const currentDate = new Date();
        // const remainingTimeInSeconds = Math.floor((timeout.getTime() - currentDate.getTime()));
        // console.log(remainingTimeInSeconds);
        // this.expirationCounter(remainingTimeInSeconds);

        return result;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  expirationCounter(timeout) {
    this.tokenSubscription.unsubscribe();
    console.log("Expired in " + timeout);
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      console.log('EXPIRED!!');
      console.log('EXPIRED!!');
      console.log('EXPIRED!!');

      this.logout();
      window.location.reload();
      
    });
  }


  
  logout() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.tokenSubscription.unsubscribe();

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });

    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.otpLocalStorageToken);

    this.isLoadingSubject.next(true);
    return this.authHttpService.logout(httpHeaders).pipe(
      map((res: { success: boolean, message: string }) => {
        if (res.success) {
          localStorage.removeItem(this.authLocalStorageToken);
          localStorage.removeItem(this.otpLocalStorageToken);
          this.isOtpVerifiedSubject$ = new BehaviorSubject<boolean>(false);
          this.isOtpSentSubject$ = new BehaviorSubject<boolean>(false);
          return this.router.navigate(['/auth/login'], {
            queryParams: {},
          });
        }
        localStorage.removeItem(this.authLocalStorageToken);
        localStorage.removeItem(this.otpLocalStorageToken);
        this.isOtpVerifiedSubject$ = new BehaviorSubject<boolean>(false);
        this.isOtpSentSubject$ = new BehaviorSubject<boolean>(false);
        return this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logoutLocal() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.otpLocalStorageToken);
    this.isOtpVerifiedSubject$ = new BehaviorSubject<boolean>(false);
    this.isOtpSentSubject$ = new BehaviorSubject<boolean>(false);
    return this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  generateOtp(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });

    let _url = config.USER_BASE_URL + config.USER.OTP;
    this.isLoadingSubject.next(true);
    return this.authHttpService.generateOtp(_url, httpHeaders).pipe(
      map((res: any) => {
        if (res?.success) {
          this.isOtpSentSubject$ = new BehaviorSubject<boolean>(true);
          const result = this.setOtpTokenInLocalStorage({ otpToken: res.tokens.otp, expiresIn: res.tokens.expiresIn });
          return result;
        } else {
          this.logout();
        }
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  verifyOtp(otp: string): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });

    const otpData = this.getOtpTokenFromLocalStorage();



    const data = {
      otpToken: otpData.otpToken,
      otp,
    }

    let _url = config.USER_BASE_URL + config.USER.OTP;
    this.isLoadingSubject.next(true);
    return this.authHttpService.verifyOtp(_url, data, httpHeaders).pipe(
      map((res: any) => {
        if (res.success) {
          this.isOtpVerifiedSubject$ = new BehaviorSubject<boolean>(true);
        } else {
          this.logout()
        }
        return res;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    ); 
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(
        map((res: any) => {
          if (res?.success) {
            return true;
          }
          return false;
        }),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(password: string, token: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(password, token)
      .pipe(
        map((res: any) => {
          if (res?.success) {
            return true;
          }
          return false;
        }),
        catchError((err) => {
          console.error('err', err);
          return of(undefined);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }


  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private setOtpTokenInLocalStorage(auth: { otpToken: string, expiresIn: Date }): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.otpToken) {
      localStorage.setItem(this.otpLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getOtpTokenFromLocalStorage(): { otpToken: string, expiresIn: Date } {
    try {
      const otpData = JSON.parse(
        localStorage.getItem(this.otpLocalStorageToken)
      );
      return otpData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
