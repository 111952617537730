import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment.dev';
import { AuthModel } from '../../_models/auth.model';
import { EncryptionService } from '../../../../Utils/encryption.service';

const API_USERS_URL = `${environment.BASE_URL}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  

  constructor(private http: HttpClient, private encService: EncryptionService) { }
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  refreshTokenFunc(refreshToken) : Observable<any> {
    let _url = environment.USER_BASE_URL + environment.USER.REFRESH_AUTH;
    // console.log(_str);

    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });


    let _res = this.http.post<AuthModel>(_url, {refreshToken: refreshToken}, { headers: httpHeaders});
    return _res;    
  }


  // public methods
  login(email: string, password: string): Observable<any> {
    let _url = environment.USER_BASE_URL + environment.USER.USER_AUTH;
    let _str = encodeURIComponent(this.encService.do_api_encrypt(JSON.stringify({email: email, password: password})));
    // console.log(_str);
    let _res = this.http.post<AuthModel>(_url, {data: _str}, { headers: {'content-type':'application/json'}});
    return _res;
    // return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
  }

  logout(headers: HttpHeaders): Observable<any> {
    let _url = environment.USER_BASE_URL + environment.USER.LOGOUT;
    let _res = this.http.post<AuthModel>(_url, {} ,{ headers });
    return _res;
    // return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
  }

  generateOtp(url: string, headers: HttpHeaders): Observable<any> {
    const res = this.http.get<any>(url, { headers });
    return res;
  }

  verifyOtp(url: string, data: any, headers: HttpHeaders): Observable<any> {
    const res = this.http.post<any>(url, data, { headers });
    return res;
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<any> {
    let _url = environment.USER_BASE_URL + environment.USER.FORGOT_PASSWORD;
    return this.http.post<any>(_url, {
      email,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  resetPassword(password: string, resetPasswordToken: string): Observable<any> {
    let _url = environment.USER_BASE_URL + environment.USER.RESET_PASSWORD + `?token=${resetPasswordToken}`;
    return this.http.post<any>(_url, {
      password,
    });
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    let _url = environment.USER_BASE_URL + environment.USER.GET_CURRENT_USER_DETAILS;
    return this.http.get<UserModel>(_url, { headers: httpHeaders});
  }
}